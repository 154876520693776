<template>
    <client-page>

        <sub-visual
        sh="제품 및 솔루션"
        sc="에프원시큐리티는 다방면으로 파트너의 정보를 보호합니다"
        tabActive="F1 UWSS"
        icon="/images/sub/visual/sv3-icon.svg"
        bg="/images/sub/visual/sv3.png">
        </sub-visual>
        
        <section class="sub-section-sm sub-section-sm--first" id="uwss">
            <v-container>
                <h3 class="sub-tit--lg text-center mb-28 mb-lg-54" data-aos="fade-up">
                    F1Security는 웹 해킹 예방, 탐지, <br class="d-none d-lg-block"/>
                    대응 제품을 개발하여 제공합니다.
                </h3>

                <div class="w-100 pa-30 pa-md-70 mb-40 mb-lg-80 bg-grey-full">
                    <v-img class="tb mx-auto mb-28 mb-lg-60" max-width="961" src="/images/sub/products/uwss/s1-img1.png"></v-img>
                    <v-img class="mo mx-auto mb-28 mb-lg-60" max-width="511" src="/images/sub/products/uwss/s1-img1-mo.png"></v-img>
                    <v-img class="tb mx-auto" max-width="961" src="/images/sub/products/uwss/s1-img2.png"></v-img>
                    <v-img class="mo mx-auto" max-width="510" src="/images/sub/products/uwss/s1-img2-mo.png"></v-img>
                </div>

                <div class="w-100 d-flex flex-column flex-lg-row radius dep2-process-group dep2-process-group1 mb-28 mb-lg-40">
                    <div class="dep2-process dep2-process1 d-lg-flex align-center justify-center pa-16 pa-md-32 py-lg-40">
                        <div>
                            <p class="font-size-14 font-size-lg-18 font-weight-bold color-white line-height-1 mb-6 mb-lg-14">
                                이슈
                            </p>
                            <p class="page-text color-white">
                                웹 보안 해결을 위한 고객의 제품 <br class="d-none d-lg-block">
                                선택이 어려움, 시스템 복잡도 증대
                            </p>
                        </div>
                    </div>
                    <div class="dep2-process-arrow d-flex align-center justify-center bg-grey py-16 py-lg-0">
                        <v-img class="d-block" max-width="38" src="/images/sub/products/uwss/process-arrow.png"></v-img>
                    </div>
                    <div class="dep2-process dep2-process2 pa-16 pa-md-32 pl-lg-60 py-lg-40">
                        <div>
                            <p class="color-point font-size-14 font-size-lg-18 font-weight-bold line-height-1 mb-6 mb-lg-14">
                                해결 방안

                            </p>
                            <p class="page-text color-grey-57">
                                고객은 UWSS 단일 서비스 선택만으로 <br class="d-none d-lg-block">
                                필수적인 다양한 웹 보안을 도입
                            </p>
                        </div>
                    </div>
                </div>

                <div class="w-100 d-flex flex-column flex-lg-row radius dep2-process-group dep2-process-group2 mb-28 mb-lg-40">
                    <div class="dep2-process dep2-process1 d-lg-flex align-center justify-center pa-16 pa-md-32 py-lg-40">
                        <div>
                            <p class="font-size-14 font-size-lg-18 font-weight-bold color-white line-height-1 mb-6 mb-lg-14">
                                이슈
                            </p>
                            <p class="page-text color-white">
                                많은 기업에는 웹 보안 전문 지식과 <br class="d-none d-lg-block">
                                경험을 보유한 전문가 부재
                            </p>
                        </div>
                    </div>
                    <div class="dep2-process-arrow d-flex align-center justify-center bg-grey py-16 py-lg-0">
                        <v-img class="d-block" max-width="38" src="/images/sub/products/uwss/process-arrow.png"></v-img>
                    </div>
                    <div class="dep2-process dep2-process2 pa-16 pa-md-32 pl-lg-60 py-lg-40">
                        <div>
                            <p class="color-point font-size-14 font-size-lg-18 font-weight-bold line-height-1 mb-6 mb-lg-14">
                                해결 방안

                            </p>
                            <p class="page-text color-grey-57">
                                클라우드 중앙관리시스템의 <br class="d-none d-lg-block">
                                직관적인 UI를 사용한 운영관리
                            </p>
                        </div>
                    </div>
                </div>

                <div class="w-100 d-flex flex-column flex-lg-row radius dep2-process-group dep2-process-group3 mb-28 mb-lg-40">
                    <div class="dep2-process dep2-process1 d-lg-flex align-center justify-center pa-16 pa-md-32 py-lg-40">
                        <div>
                            <p class="font-size-14 font-size-lg-18 font-weight-bold color-white line-height-1 mb-6 mb-lg-14">
                                이슈
                            </p>
                            <p class="page-text color-white">
                                웹 보안 제품 도입이 절실한 <br class="d-none d-lg-block">
                                대다수 기업에게는 너무 고가
                            </p>
                        </div>
                    </div>
                    <div class="dep2-process-arrow d-flex align-center justify-center bg-grey py-16 py-lg-0">
                        <v-img class="d-block" max-width="38" src="/images/sub/products/uwss/process-arrow.png"></v-img>
                    </div>
                    <div class="dep2-process dep2-process2 pa-16 pa-md-32 pl-lg-60 py-lg-40">
                        <div>
                            <p class="color-point font-size-14 font-size-lg-18 font-weight-bold line-height-1 mb-6 mb-lg-14">
                                해결 방안

                            </p>
                            <p class="page-text color-grey-57">
                                소프트웨어 & 클라우드 기반 <br class="d-none d-lg-block">
                                합리적인 가격체계로 도입 가능
                            </p>
                        </div>
                    </div>
                </div>
            </v-container>
        </section>

        <section class="sub-section-sm">
            <v-container>
                <h4 class="sub-tit--md text-center mb-26 mb-lg-42" data-aos="fade-up">
                    UWSS는 차별화 요소로 고객에게 <br class="d-none d-lg-block"/>
                    다양한 가치를 제공합니다
                </h4>

                <div class="client-table">
                    <div class="thead d-none d-lg-block">
                        <div class="tr">
                            <div class="th">고객가치</div>
                            <div class="th color-point">차별화 요소</div>
                            <div class="th">유사 제품</div>
                        </div>
                    </div>
                    <div class="tbody">
                        <div class="tr">
                            <div class="td">
                                통합 서비스
                            </div>
                            <div class="td ">
                                <span class="d-block d-lg-none">차별화 요소</span>
                                통합 운영관리 가능한 웹 보안 종합솔루션 제공 <br>
                                ※ 고객 선택에 따른 맞춤형 서비스
                            </div>
                            <div class="td">
                                <span class="d-block d-lg-none">유사 제품</span>
                                개별 제품 각각 구매, 개별 제품 각각 관리로 편의성 저하
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td">
                                서버구축 비용절감
                            </div>
                            <div class="td ">
                                <span class="d-block d-lg-none">차별화 요소</span>
                                클라우드에 통합 중앙관리 플랫폼을 구축하여 제공 <br>
                                ※ 고객 서버 구축/운영 비용 Zero
                            </div>
                            <div class="td">
                                <span class="d-block d-lg-none">유사 제품</span>
                                관리서버 구매 및 구축, 운영비용 발생
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td">
                                즉각적인 설치운영
                            </div>
                            <div class="td ">
                                <span class="d-block d-lg-none">차별화 요소</span>
                                Agent 소프트웨어 다운로드/설치 후 <br>
                                즉시 사용 가능
                            </div>
                            <div class="td">
                                <span class="d-block d-lg-none">유사 제품</span>
                                SECaaS(Proxy 방식)은 DNS 변경/적용 등 시간소요
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td">
                                운영관리 효율성
                            </div>
                            <div class="td ">
                                <span class="d-block d-lg-none">차별화 요소</span>
                                단일 관리용 웹사이트를 통한 통합 운영관리 가능 <br>
                                ※ 복수 제품을 단일 창구로 계약/관리(업무 효율화)
                            </div>
                            <div class="td">
                                <span class="d-block d-lg-none">유사 제품</span>
                                개별 제품 관리시스템 제공
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td">
                                사용자 편의성
                            </div>
                            <div class="td ">
                                <span class="d-block d-lg-none">차별화 요소</span>
                                통합 대시보드, 통합 보고서 등 보안관리 <br class="d-none d-lg-block">
                                편의성 증대
                            </div>
                            <div class="td">
                                <span class="d-block d-lg-none">유사 제품</span>
                                미 제공, 개별 제품 대시보드/보고서
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td">
                                장애 시 웹 접속 보장
                            </div>
                            <div class="td ">
                                <span class="d-block d-lg-none">차별화 요소</span>
                                플랫폼, Agent 장애에 따른 <br class="d-none d-lg-block">
                                고객 웹 접속 영향 없음
                            </div>
                            <div class="td">
                                <span class="d-block d-lg-none">유사 제품</span>
                                SECaaS(Proxy 방식)은 서비스 인프라 장애 시 <br class="d-none d-lg-block">
                                고객 웹사이트 접속도 중단
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td">
                                해킹 시 안전한 플랫폼
                            </div>
                            <div class="td ">
                                <span class="d-block d-lg-none">차별화 요소</span>
                                클라우드 플랫폼은 어떠한 웹 트래픽도 우회, <br class="d-none d-lg-block">
                                저장하지 않기 때문에, 플랫폼이 해킹되어도 <br class="d-none d-lg-block">
                                정보유출 미 발생
                            </div>
                            <div class="td">
                                <span class="d-block d-lg-none">유사 제품</span>
                                <div class="bar-txt">
                                    - SECaaS(Proxy 방식)은 서비스 인프라에 모든 웹 트래픽 우회 <br class="d-none d-lg-block">
                                    분석/저장(가능) <br class="d-none d-lg-block">
                                </div>
                                <div class="bar-txt">
                                    - 인프라 장애 시 트래픽 정보도 유출 가능
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </section>

        <section class="sub-section-sm sub-section--last">
            <v-container>
                <h3 class="sub-tit--md text-center mb-26 mb-lg-42" data-aos="fade-up">
                    웹 보안 및 클라우드 중앙관리시스템을 <br class="d-none d-lg-block">
                    One 플랫폼 One 서비스로 제공합니다.
                </h3>

                <div class="radius-md border mb-40 mb-lg-80">

                    <p class="w-100 bg-point color-white text-center font-weight-bold font-size-16 font-size-lg-22 line-height-1 pa-18">
                        웹보안 서비스
                    </p>
    
                    <v-row no-gutters>
                        <v-col cols="12" lg="4" class="service-item">
                            <div class="d-flex flex-column align-center justify-center py-24 px-16">
                                <div class="bg-grey radius-md d-flex align-center justify-center mb-6 mb-lg-14" style="width: 70px; height: 70px;">
                                    <v-img class="d-block" max-width="46" src="/images/sub/products/uwss/s3-icon1-1.png"></v-img>
                                </div>
                                <p class="font-size-16 font-size-lg-22 text-center line-height-1 font-weight-700">
                                    웹 방화벽
                                </p>
                            </div>
                            <div class="service-item-option pa-16 bg-grey">
                                <p class="color-point font-size-14 font-size-lg-18 line-height-1 text-center">
                                    Software Agent
                                </p>
                            </div>
                            <div class="pa-16 pa-lg-34">
                                <p class="page-text-sm color-gray bar-txt mb-10 mb-lg-24">
                                    - OWASP Top 10 웹 어플리케이션 공격 탐지 <br class="d-none d-lg-block">
                                    및 차단 <br>
                                    ※ 탐지Only 모드, Blocking 모드 선택 가능
                                </p>
                                <p class="page-text-sm color-gray bar-txt">
                                    - 사용자 정의 규칙 작성, IP/Domain 예외 처리, <br class="d-none d-lg-block">
                                    Parameter Block
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="4" class="service-item">
                            <div class="d-flex flex-column align-center justify-center py-24 px-16">
                                <div class="bg-grey radius-md d-flex align-center justify-center mb-6 mb-lg-14" style="width: 70px; height: 70px;">
                                    <v-img class="d-block" max-width="46" src="/images/sub/products/uwss/s3-icon1-2.png"></v-img>
                                </div>
                                <p class="font-size-16 font-size-lg-22 text-center line-height-1 font-weight-700">
                                    안티 웹쉘
                                </p>
                            </div>
                            <div class="service-item-option pa-16 bg-grey">
                                <p class="color-point font-size-14 font-size-lg-18 line-height-1 text-center">
                                    Software Agent
                                </p>
                            </div>
                            <div class="pa-16 pa-lg-34">
                                <p class="page-text-sm color-gray bar-txt mb-10 mb-lg-24">
                                    - 웹쉘 탐지 및 격리(+3,500개 이상의 탐지패턴) <br>
                                    ※ Regex, Hash, Url, SSDeep, obfuscation  <br class="d-none d-lg-block">
                                    알고리즘 적용 탐지
                                </p>
                                <p class="page-text-sm color-gray bar-txt">
                                    - 실시간 탐지 On/Off, 수동 검사 설정, <br class="d-none d-lg-block">
                                    Whitelist 등록
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="4" class="service-item">
                            <div class="d-flex flex-column align-center justify-center py-24 px-16">
                                <div class="bg-grey radius-md d-flex align-center justify-center mb-6 mb-lg-14" style="width: 70px; height: 70px;">
                                    <v-img class="d-block" max-width="46" src="/images/sub/products/uwss/s3-icon1-3.png"></v-img>
                                </div>
                                <p class="font-size-16 font-size-lg-22 text-center line-height-1 font-weight-700">
                                    웹 악성코드 유포 탐지
                                </p>
                            </div>
                            <div class="service-item-option pa-16 bg-grey">
                                <p class="color-point font-size-14 font-size-lg-18 line-height-1 text-center">
                                    Remote Scanning
                                </p>
                            </div>
                            <div class="pa-16 pa-lg-34">
                                <p class="page-text-sm color-gray bar-txt mb-10 mb-lg-24">
                                    - 악성코드 경유 및 유포지 URL 탐지 <br>
                                    (+175,500개 이상의 탐지패턴)
                                </p>
                                <p class="page-text-sm color-gray bar-txt">
                                    - 정기 및 수동 검사 설정, Path Depth/Sub Page
                                    Link/ Navigation Page 설정
                                </p>
                            </div>
                        </v-col>
                    </v-row>

                </div>

                <div class="radius-md border">

                    <p class="w-100 bg-point color-white text-center font-weight-bold font-size-16 font-size-lg-22 line-height-1 pa-18">
                        중앙관리 시스템
                    </p>
    
                    <v-row no-gutters class="service-item-group_option1">
                        <v-col cols="12" lg="3" class="service-item">
                            <div class="d-flex flex-column align-center justify-center py-24 px-16">
                                <div class="bg-grey radius-md d-flex align-center justify-center mb-6 mb-lg-14" style="width: 70px; height: 70px;">
                                    <v-img class="d-block" max-width="48" src="/images/sub/products/uwss/s3-icon2-1.png"></v-img>
                                </div>
                                <p class="font-size-16 font-size-lg-22 text-center line-height-1 font-weight-700">
                                    설치 및 설정
                                </p>
                            </div>
                            <div class="service-item-option pa-16 bg-grey">
                                <p class="color-point font-size-14 font-size-lg-18 line-height-1 text-center">
                                    Cloud
                                </p>
                            </div>
                            <div class="pa-16 pa-lg-34">
                                <p class="page-text-sm color-gray bar-txt">
                                    - Agent 다운로드, 등록, 보안 정책
                                    조회 및 변경
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="3" class="service-item">
                            <div class="d-flex flex-column align-center justify-center py-24 px-16">
                                <div class="bg-grey radius-md d-flex align-center justify-center mb-6 mb-lg-14" style="width: 70px; height: 70px;">
                                    <v-img class="d-block" max-width="48" src="/images/sub/products/uwss/s3-icon2-2.png"></v-img>
                                </div>
                                <p class="font-size-16 font-size-lg-22 text-center line-height-1 font-weight-700">
                                    통합 서비스
                                </p>
                            </div>
                            <div class="service-item-option pa-16 bg-grey">
                                <p class="color-point font-size-14 font-size-lg-18 line-height-1 text-center">
                                    Cloud
                                </p>
                            </div>
                            <div class="pa-16 pa-lg-34">
                                <p class="page-text-sm color-gray bar-txt">
                                    - 통합 대시보드 조회, <br class="d-none d-lg-block">
                                    통합 보고서 조회 및 다운로드
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="3" class="service-item">
                            <div class="d-flex flex-column align-center justify-center py-24 px-16">
                                <div class="bg-grey radius-md d-flex align-center justify-center mb-6 mb-lg-14" style="width: 70px; height: 70px;">
                                    <v-img class="d-block" max-width="48" src="/images/sub/products/uwss/s3-icon2-3.png"></v-img>
                                </div>
                                <p class="font-size-16 font-size-lg-22 text-center line-height-1 font-weight-700">
                                    로깅 및 알림
                                </p>
                            </div>
                            <div class="service-item-option pa-16 bg-grey">
                                <p class="color-point font-size-14 font-size-lg-18 line-height-1 text-center">
                                    Cloud
                                </p>
                            </div>
                            <div class="pa-16 pa-lg-34">
                                <p class="page-text-sm color-gray bar-txt">
                                    - 탐지, 차단, 격리 등 보안이벤트
                                    내역 로깅 및 실시간 이메일 알림
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="3" class="service-item">
                            <div class="d-flex flex-column align-center justify-center py-24 px-16">
                                <div class="bg-grey radius-md d-flex align-center justify-center mb-6 mb-lg-14" style="width: 70px; height: 70px;">
                                    <v-img class="d-block" max-width="48" src="/images/sub/products/uwss/s3-icon2-4.png"></v-img>
                                </div>
                                <p class="font-size-16 font-size-lg-22 text-center line-height-1 font-weight-700">
                                    마이 페이지
                                </p>
                            </div>
                            <div class="service-item-option pa-16 bg-grey">
                                <p class="color-point font-size-14 font-size-lg-18 line-height-1 text-center">
                                    Cloud
                                </p>
                            </div>
                            <div class="pa-16 pa-lg-34">
                                <p class="page-text-sm color-gray bar-txt">
                                    - 사용자, 서비스 타입, 빌링 정보 등
                                    조회, 등록 및 변경
                                </p>
                            </div>
                        </v-col>
                    </v-row>

                </div>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style scoped>
    .dep2-process-group {
        border-radius: var(--radius);
        border: 1px solid var(--border-color);
    }

    .dep2-process{
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .dep2-process1{
        background-position: left center;
        overflow: hidden;
    }

    .dep2-process2{
        background-position: right center;
        border-left: 0 !important;
        overflow: hidden;
    }

    .dep2-process-arrow{
        width: 100%;
        border: 0;
        border: 1px solid var(--border-color);
        border-left: 0;
        border-right: 0;
    }

    .dep2-process-arrow .v-image{
        transform: rotate(90deg);
    }

    .dep2-process-group1 .dep2-process1{
        background-image: url(/images/sub/products/uwss/s2-img1-1.png);
    }

    .dep2-process-group1 .dep2-process2{
        background-image: url(/images/sub/products/uwss/s2-img1-2.png);
    }

    .dep2-process-group2 .dep2-process1{
        background-image: url(/images/sub/products/uwss/s2-img2-1.png);
    }

    .dep2-process-group2 .dep2-process2{
        background-image: url(/images/sub/products/uwss/s2-img2-2.png);
    }

    .dep2-process-group3 .dep2-process1{
        background-image: url(/images/sub/products/uwss/s2-img3-1.png);
    }

    .dep2-process-group3 .dep2-process2{
        background-image: url(/images/sub/products/uwss/s2-img3-2.png);
    }

    .service-item-option{
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
    }

    @media (min-width:576px){
    }
    @media (min-width:768px){
    }
    @media (min-width:1024px){
        .dep2-process-group {
            border-radius: 0;
            border: 0;
        }
        .service-item>div:last-child{
            min-height: 180px;
        }

        .service-item:not(:last-child){
            border-right: 1px solid var(--border-color);
        }

        .service-item-group_option1{
            position: relative;
        }

        .service-item-group_option1::after{
            content: "Cloud";
            width: 100%;
            padding: 16px;
            background-color: #f9f9f9;
            color: var(--v-primary-base);
            font-size: 1.8rem;
            line-height: 1;
            text-align: center;
            border-top: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);
            display: block;
            position: absolute;
            left: 0;
            top: 154px;
        }

        .dep2-process1{
            border-radius: var(--radius) 0 0 var(--radius);
        }

        .dep2-process2{
            border-radius: 0 var(--radius) var(--radius) 0;
            border: 1px solid var(--border-color);
        }

        .dep2-process{
            width: calc((100% - 60px) / 2);
        }

        .dep2-process-arrow{
            width: 60px;
            border: 1px solid var(--border-color);
        }

        .dep2-process-arrow .v-image{
            transform: rotate(0);
        }

        .dep2-process1>div{
            width: 100%;
            max-width: 260px;
        }

    }
    @media (min-width:1200px){
    }

    /* Title */
    .tit-txt-wrap{
        margin-bottom: 40px;
    }
    .tit-wrap{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }
    .tit-txt-wrap .tit-wrap{
        margin-bottom: 10px;
    }
    .tit{
        width: 100%;
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 1.2;
        word-break: keep-all;
    }
    .tit.font-notoserif{
        line-height: 1.4;
    }
    .tit--xs{
        font-size: 1.8rem;
        font-weight: 600;
    }
    .tit--sm{
        font-size: 2rem;
    }
    .tit--lg{
        font-size: 3.2rem;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 38px;
    }
    .tit-txt{
        font-size: 1.4rem;
    }


    @media (min-width:576px){
    }
    @media (min-width:768px){
        .tit{
            font-size: 3rem;
        }
        .tit--xs{
            font-size: 1.8rem;
        }
        .tit--lg{
            font-size: 4rem;
            line-height: 50px;
        }
    }
    @media (min-width:1024px){
        .tit-txt-wrap{
            margin-bottom: 60px;
        }
        .tit-wrap{
            margin-bottom: 60px;
        }
        .tit-txt-wrap .tit-wrap{
            margin-bottom: 20px;
        }
        .tit{
            font-size: 3.6rem;
        }
        .tit--xs{
            font-size: 2rem;
        }
        .tit--sm{
            font-size: 2.4rem;
        }
        .tit--lg{
            font-size: 5.2rem;
            line-height: 66px;
            margin-bottom: 72px
        }
        .tit-txt{
            font-size: 1.6rem;
        }
    }
    @media (min-width:1200px){
    }

/* Page Text */
.page-text{
    font-size: 1.6rem;
    line-height: 24px;
}
.page-text-sm{
    font-size: 1.4rem;
    line-height: 20px;
}
.page-text-lg{
    font-size: 1.6rem;
    line-height: 24px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .page-text{
        font-size: 2rem;
        line-height: 30px;
    }
    .page-text-sm{
        font-size: 1.8rem;
        line-height: 28px;
    }
    .page-text-lg{
        font-size: 2.2rem;
        line-height: 30px;
    }
}
@media (min-width:1200px){
}
</style>